import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { ProductorsOrganisationService } from 'src/app/_services/productors-organisation.service';
import { ProductorsOrganisation } from 'src/app/_models/productors-organisation/productors-organisation';

@Component({
  selector: 'app-productors-organisation',
  templateUrl: './productors-organisation.component.html',
  styleUrls: ['./productors-organisation.component.sass']
})
export class ProductorsOrganisationComponent implements OnInit {

  id: number

  productorsOrganisationForm: FormGroup
  submitted: boolean

  productorsOrg: ProductorsOrganisation

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _productorsOrganisation: ProductorsOrganisationService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = +params['id'];
      
      this._productorsOrganisation.getProductorsOrganisation(this.id).subscribe( data => {
        this.productorsOrg = data

        this.productorsOrganisationForm = this.formBuilder.group({
          name: [this.productorsOrg.name, Validators.required],
          mail: [this.productorsOrg.mail, Validators.required]
        })
      })
    });
  }

  get f() { return this.productorsOrganisationForm.controls }

  goBack(){
    this.router.navigate(['configuration']);
  }

  onSubmit(){
    this.submitted = true;

    if(this.productorsOrganisationForm.invalid){
      return
    }

    this.productorsOrg = this.productorsOrganisationForm.value

    this._productorsOrganisation.updateProductorsOrganisation(this.id, this.productorsOrganisationForm.value).subscribe(data => {
        this.productorsOrg = data
        this.router.navigate(['configuration'])
    }) 
  }
}
