import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";

import { AccountService } from "./../../_services/account.service";
import { AuthenticationService } from "./../../_services/authentication.service";
import { SettingService } from "./../../_services/setting.service";

declare let videojs: any;

@Component({
  selector: "app-validation-functionment",
  templateUrl: "./validation-functionment.component.html",
  styleUrls: ["./validation-functionment.component.sass"],
})
export class ValidationFunctionmentComponent implements OnInit {
  vidObj: any;
  video: any = "./../../assets/video/video_guian2.mp4";

  vidEnded: boolean = false;
  submitted: boolean;

  acceptForm: FormGroup;

  id: number;
  currentAccount: any;
  setting: any;

  @ViewChild("myvid") vid: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _account: AccountService,
    private _authentication: AuthenticationService,
    private _setting: SettingService
  ) {}

  ngOnInit() {
    const options = {
      controls: true,
      autoplay: false,
      preload: "auto",
    };

    this._setting.getSetting(1).subscribe((data) => {
      this.setting = data;
    });

    this._authentication.currentAccount.subscribe((x) => {
      this.currentAccount = x;

      this.acceptForm = this.formBuilder.group({
        id: [this.currentAccount.id, Validators.required],
        validationAcceptFunctionment: ["", Validators.requiredTrue],
      });
    });

    this.vidObj = videojs(this.vid.nativeElement, options, () => {
      console.log("Your player is ready!");

      this.vidObj.on("timeupdate", () => {
        this.vidObj.controls(false);
      });
    });
  }

  get f() {
    return this.acceptForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.acceptForm.invalid) {
      return;
    }

    this._account.updateAccount(this.acceptForm.value).subscribe((data) => {
      this.currentAccount.validationAcceptFunctionment = true;
      //sessionStorage.setItem('currentAccount', JSON.stringify(this.currentAccount));
      this._authentication.reloadSession(this.currentAccount);
      this.router.navigate(["/subscription/password"]);
    });
  }
}
