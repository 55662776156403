import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CONFIG } from '../app.constants';

import { Productor } from 'src/app/_models/productor/productor';

@Injectable()
export class ProductorService{

    constructor(private http: HttpClient) { }

    private extractData(res: Response) {
        const body = res;
        return body || { };
    }

    getProductors(id: number): Observable<any> {
        return this.http.get(CONFIG.apiUrl[CONFIG.mode] + `productor/productors/${id}`).pipe(
          map(this.extractData));
    }

    getProductor(id: number): Observable<any> {
        return this.http.get(CONFIG.apiUrl[CONFIG.mode] + `productor/${id}`).pipe(
          map(this.extractData));
    }

    getProductorByRef(ref: string): Observable<any> {
      return this.http.get(CONFIG.apiUrl[CONFIG.mode] + `productor/reference/${ref}`).pipe(
        map(this.extractData));
    }

    insertProductor(productor: Productor): Observable<any>{
        return this.http.post(CONFIG.apiUrl[CONFIG.mode] + 'productor', JSON.stringify(productor))
        .pipe(
          map(this.extractData)
        )
    }

    updateProductor(id: number, productor: Productor): Observable<any>{
        return this.http.post(CONFIG.apiUrl[CONFIG.mode] + `productor/${id}`, JSON.stringify(productor))
        .pipe(
          map(this.extractData)
        )
    }
}