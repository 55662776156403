import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './_guards/auth.guard';

// COMPOSANTS //
import { LoginComponent } from './login/login.component'
import { HomeComponent } from './home/home.component'

import { ValidationFunctionmentComponent } from './subscription/validation-functionment/validation-functionment.component'
import { PasswordChangeComponent } from './password-change/password-change.component'
import { ValidationCgcpComponent } from './subscription/validation-cgcp/validation-cgcp.component'

import { ProductorsComponent } from './productors/productors.component'
import { ProductorComponent } from './productors/productor/productor.component'
import { ProductorDetailsComponent } from './productors/productor-details/productor-details.component'

import { AccountComponent } from './account/account.component'
import { AccountDocumentComponent } from './account/account-document/account-document.component'
import { AccountCotisationComponent } from './account/account-cotisation/account-cotisation.component' 

import { ConfigurationComponent } from './configuration/configuration.component';
import { ProductorsOrganisationComponent } from './configuration/productors-organisation/productors-organisation.component'
import { BrokerComponent } from './configuration/broker/broker.component'
import { InsurerComponent } from './configuration/insurer/insurer.component'

import { InformationsComponent } from './informations/informations.component'
import { AssistanceComponent } from './informations/assistance/assistance.component'
import { GeneralConditionsComponent } from './informations/general-conditions/general-conditions.component'
import { VideosComponent } from './informations/videos/videos.component'
import { PartnersComponent } from './informations/partners/partners.component'
import { LegalInformationsComponent } from './informations/legal-informations/legal-informations.component'
import { LegalNoticeComponent } from './informations/legal-informations/legal-notice/legal-notice.component'
import { LegalMentionComponent } from './informations/legal-informations/legal-mention/legal-mention.component'

import { ConfigurationOpComponent } from './configuration-op/configuration-op.component'

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'login', component: LoginComponent },

  { path: 'account', component: AccountComponent, canActivate: [AuthGuard], data: {right: 'AccessMyAccount'} },
  { path: 'account/:pdf', component: AccountComponent, canActivate: [AuthGuard], data: {right: 'AccessMyAccount'} },
  { path: 'account/service/cotisation', component: AccountCotisationComponent, canActivate: [AuthGuard], data: {right: 'AccessMyAccount'} },

  { path: 'productors', component: ProductorsComponent, canActivate: [AuthGuard], data: {right: 'ReadProductorList'}},
  { path: 'productors/productor', component: ProductorComponent, canActivate: [AuthGuard], data: {right: 'CreateProductor'}},
  { path: 'productors/productor/:id', component: ProductorComponent, canActivate: [AuthGuard], data: {right: 'UpdateProductor'}},
  { path: 'productors/productor/details/:id', component: ProductorDetailsComponent, canActivate: [AuthGuard], data: {right: 'ReadCardProductor'}},
  
  { path: 'subscription/functionment', component: ValidationFunctionmentComponent, canActivate: [AuthGuard], data: {right: 'Subscribe'} },
  { path: 'subscription/password', component: PasswordChangeComponent, canActivate: [AuthGuard], data: {right: 'Subscribe'} },
  { path: 'subscription/productor', component: ProductorDetailsComponent, canActivate: [AuthGuard], data: {right: 'ReadCardProductor'}},
  { path: 'subscription/cgcp', component: ValidationCgcpComponent, canActivate: [AuthGuard], data: {right: 'Subscribe'} },

  { path: 'configuration', component: ConfigurationComponent, canActivate: [AuthGuard], data: {right: 'AccessConfiguration'} },
  { path: 'configuration/productors-organisation/:id', component: ProductorsOrganisationComponent, canActivate: [AuthGuard], data: {right: 'AccessConfiguration'} },
  { path: 'configuration/broker/:id', component: BrokerComponent, canActivate: [AuthGuard], data: {right: 'AccessConfiguration'} },
  { path: 'configuration/insurer/:id', component: InsurerComponent, canActivate: [AuthGuard], data: {right: 'AccessConfiguration'} },

  { path: 'informations/assistance', component: AssistanceComponent, canActivate: [AuthGuard], data: {right: 'ReadInformation'} },   
  { path: 'informations/videos', component: VideosComponent, canActivate: [AuthGuard], data: {right: 'ReadInformation'} },  
  { path: 'informations/partners', component: PartnersComponent, canActivate: [AuthGuard], data: {right: 'ReadInformation'} },
  { path: 'informations/legal-informations/legal-notice', component: LegalNoticeComponent, canActivate: [AuthGuard] }, 
  { path: 'informations/legal-informations/legal-mention', component: LegalMentionComponent, canActivate: [AuthGuard] },

  { path: 'op/configuration', component: ConfigurationOpComponent, canActivate: [AuthGuard], data: {right: 'AccessConfigurationOp'} },

  //non affecter
  { path: 'informations/legal-informations', component: LegalInformationsComponent, canActivate: [AuthGuard], data: {right: 'zero'} },
  { path: 'account/documents', component: AccountDocumentComponent, canActivate: [AuthGuard], data: {right: 'zero'}  },
  { path: 'informations', component: InformationsComponent, canActivate: [AuthGuard], data: {right: 'zero'} },
  { path: 'informations/general-conditions', component: GeneralConditionsComponent, canActivate: [AuthGuard], data: {right: 'zero'} },   
  
  

  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
