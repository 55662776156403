import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CONFIG } from '../app.constants';

import { Account } from 'src/app/_models/account/account';

@Injectable()
export class AuthenticationService{
    private currentAccountSubject: BehaviorSubject<Account>;
    public currentAccount: Observable<Account>;

    constructor(private http: HttpClient) { 
        this.currentAccountSubject = new BehaviorSubject<Account>(JSON.parse(sessionStorage.getItem('currentAccount')));
        this.currentAccount = this.currentAccountSubject.asObservable();
    }

    private extractData(res: Response) {
        const body = res;
        return body || { };
    }

    /*test(username: string) : Observable<any>{
        return this.http.get(CONFIG.apiUrl[CONFIG.mode] + `user/username/${username}`).pipe(
            map(this.extractData))
    }*/

    login(login: string, password: string) {
        return this.http.post<any>(CONFIG.apiUrl[CONFIG.mode] + 'account/authenticate', {login: login, password: password })
        .pipe(map(result =>{
            if (result.state) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    if(result.account.id !== 0)
                    {
                        sessionStorage.setItem('currentAccount', JSON.stringify(result.account));
                        this.currentAccountSubject.next(result.account);
                    }
            }
            return result
        }))
    }

    logout() {
        // remove user from local storage to log user out
        this.currentAccountSubject.next(null);
        sessionStorage.removeItem('currentAccount');
    }

    loginCode(code: string) {
        return this.http.post<any>(CONFIG.apiUrl[CONFIG.mode] + 'account/authenticate/tmp', {code: code})
        .pipe(map(result =>{
            if (result.state) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                if(result.account.id !== 0)
                {
                    sessionStorage.setItem('currentAccount', JSON.stringify(result.account));
                    this.currentAccountSubject.next(result.account);
                }
            }
            return result
        }))
    }

    reloadSession(account: any){
        sessionStorage.setItem('currentAccount', JSON.stringify(account));
        this.currentAccountSubject.next(account);
    }

}