import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { AuthenticationService } from './../_services/authentication.service'
import { ProductorsOrganisationService } from './../_services/productors-organisation.service';
import { ProductorsOrganisation } from './../_models/productors-organisation/productors-organisation';


@Component({
  selector: 'app-configuration-op',
  templateUrl: './configuration-op.component.html',
  styleUrls: ['./configuration-op.component.sass']
})
export class ConfigurationOpComponent implements OnInit {

  currentAccount: any

  productorsOrganisationForm: FormGroup
  submitted: boolean

  productorsOrg: ProductorsOrganisation

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _productorsOrganisation: ProductorsOrganisationService,
    private _authentication: AuthenticationService
  ) { }

  ngOnInit() {
    this._authentication.currentAccount.subscribe(x => { 
      this.currentAccount = x
      console.log(this.currentAccount)

      this._productorsOrganisation.getProductorsOrganisation(this.currentAccount.productorsOrganisationId).subscribe( data => {
        this.productorsOrg = data
        console.log(this.productorsOrg)

        this.productorsOrganisationForm = this.formBuilder.group({
          fee: [this.productorsOrg.applicationFee, Validators.required]
        })
      })
    })
  }

  get f() { return this.productorsOrganisationForm.controls }

  onSubmit(){
    this.submitted = true;

    if(this.productorsOrganisationForm.invalid){
      return
    }

    this.productorsOrg.applicationFee = this.productorsOrganisationForm.value.fee

    this._productorsOrganisation.updateProductorsOrganisation(this.currentAccount.productorsOrganisationId, this.productorsOrg).subscribe(data => {
        this.productorsOrg = data
        this.router.navigate(['/productors'])
    }) 
  }

}
