import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { CONFIG } from './app.constants';
import { Helpers } from './_helpers/helpers'

// SERVICES //
import { AuthenticationService } from './_services/authentication.service';

// MODELS //
import { Account } from './_models/account/account';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'ugpban-app';

  currentAccount: Account
  CONFIG: any = CONFIG
  
  constructor( 
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public helpers: Helpers,
    private authenticationService: AuthenticationService,){

      this.authenticationService.currentAccount
        .subscribe(x => { 
          this.currentAccount = x
        });
  }

  ngOnInit() {

  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
}
}
