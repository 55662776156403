import { Injectable } from '@angular/core';
import { CONFIG } from '../app.constants';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PDFService {

  constructor(private http: HttpClient) { }

  private extractData(res: Response) {
    const body = res;
    return body || { };
  }

  getPDFCP(id: number): Promise<any> {
    return this.http
      .get<any>(CONFIG.apiUrl[CONFIG.mode] + `pdf/cp/${id}`, {responseType: 'blob' as 'json'}).toPromise()
  }
  
  generatePDFCP(id: number): Observable<any> {
    return this.http.get(CONFIG.apiUrl[CONFIG.mode] + `pdf/generate/cp/${id}`).pipe(
      map(this.extractData));
  }

  getPDFAttestation(id: number): Promise<any> {
    return this.http
      .get<any>(CONFIG.apiUrl[CONFIG.mode] + `pdf/attestation/${id}`, {responseType: 'blob' as 'json'}).toPromise()
  }
  
  generatePDFAttestation(id: number): Observable<any> {
    return this.http.get(CONFIG.apiUrl[CONFIG.mode] + `pdf/generate/attestation/${id}`).pipe(
      map(this.extractData));
  }
}
