import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { BrokerService } from 'src/app/_services/broker.service';
import { Broker } from 'src/app/_models/broker/broker';


@Component({
  selector: 'app-broker',
  templateUrl: './broker.component.html',
  styleUrls: ['./broker.component.sass']
})
export class BrokerComponent implements OnInit {

  id: number

  brokerForm: FormGroup
  submitted: boolean

  broker: Broker

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _broker: BrokerService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = +params['id'];
      
      this._broker.getBroker(this.id).subscribe( data => {
        this.broker = data

        this.brokerForm = this.formBuilder.group({
          name: [this.broker.name, Validators.required],
          mail: [this.broker.mail, Validators.required]
        })
      })
    });
  }

  get f() { return this.brokerForm.controls }

  goBack(){
    this.router.navigate(['configuration']);
  }

  onSubmit(){
    this.submitted = true;

    if(this.brokerForm.invalid){
      return
    }

    this.broker = this.brokerForm.value

    this._broker.updateBroker(this.id, this.brokerForm.value).subscribe(data => {
        this.broker = data
        this.router.navigate(['configuration'])
    }) 
  }
}
