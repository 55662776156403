import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";

import { AuthenticationService } from "./../_services/authentication.service";
import { AccountPasswordService } from "./../_services/account-password";

declare let videojs: any;

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.sass"],
})
export class HomeComponent implements OnInit {
  vidObj: any;
  video: any = "./../../assets/video/video_guian2.mp4";

  vidEnded: boolean = false;
  submitted: boolean;

  tmpPasswordForm: FormGroup;

  error: any;

  @ViewChild("myvid") vid: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _authentication: AuthenticationService,
    private _accountPassword: AccountPasswordService
  ) {}

  ngOnInit() {
    const options = {
      controls: true,
      autoplay: false,
      preload: "auto",
    };

    this.tmpPasswordForm = this.formBuilder.group({
      tmpPassword: ["", Validators.required],
    });

    this.vidObj = videojs(this.vid.nativeElement, options, () => {
      console.log("Your player is ready!");

      this.vidObj.on("timeupdate", () => {
        this.vidObj.controls(false);
        // const currentTime = this.vidObj.currentTime();

        // if (!this.vidObj.lasttime) {
        //   this.vidObj.lasttime = 0.5;
        // } else {
        //   //on ajoute 0.5 seconde
        //   this.vidObj.lasttime += 0.5;
        // }

        // if (currentTime > this.vidObj.lasttime) {
        //   console.log("if");
        //   this.vidObj.currentTime(this.vidObj.lasttime);
        // } else {
        //   this.vidObj.lasttime = currentTime;
        // }
      });
    });
    this.vidObj = new videojs(
      this.vid.nativeElement,
      options,
      function onPlayerReady() {
        videojs.log("Your player is ready!");
      }
    );
  }

  get f() {
    return this.tmpPasswordForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.tmpPasswordForm.invalid) {
      return;
    }

    this._authentication
      .loginCode(this.tmpPasswordForm.value.tmpPassword)
      .subscribe((data) => {
        if (data.errors.length < 1) {
          this.router.navigate(["/subscription/functionment"]);
        } else {
          this.error = data.errors[0];
        }
      });

    //this.router.navigate(['/validation/functionment', data.id])
  }
}
