import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    userId: string = ""

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentAccount = JSON.parse(sessionStorage.getItem('currentAccount'));
        //console.log(currentAccount)

        if (currentAccount && currentAccount.token) {
            request = request.clone({
                setHeaders: { 
                    Authorization: `Bearer ${currentAccount.token}`
                }
            });
        }

        request = request.clone({
            headers : request.headers
            .set('Content-Type', 'application/json')
            .set('Access-Control-Allow-Origin','*')
            //.set('userId', this.userId.toString())
        })

        return next.handle(request);
    }
}