import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

declare let videojs: any;

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.sass']
})
export class VideosComponent implements OnInit {

  vidObj: any
  video: any =  './../../assets/video/fonctionnement.mp4'

  vidObj2: any
  video2: any =  './../../assets/video/presentation.mp4'

  @ViewChild('vid01') vid: ElementRef;

  @ViewChild('vid02') vid2: ElementRef;

  constructor(
    private router: Router) { }

  ngOnInit() {
    const options = {
      controls: true,
      autoplay: false,
      preload: 'auto'
    };

    const options2 = {
      controls: true,
      autoplay: false,
      preload: 'auto'
    };

    this.vidObj = new videojs(this.vid.nativeElement, options, function onPlayerReady() {
      videojs.log('Your player is ready!');
    });

    this.vidObj2 = new videojs(this.vid2.nativeElement, options2, function onPlayerReady() {
      videojs.log('Your player is ready!');
    });
  }

}
