import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Helpers } from './../../_helpers/helpers'

// SERVICE //
import { ProductorService } from './../../_services/productor.service';
import { AccountService } from './../../_services/account.service'
import { AuthenticationService } from './../../_services/authentication.service'
import { PDFService } from './../../_services/pdf.service'
import { SettingService } from './../../_services/setting.service'

// MODEL //
import { Productor } from 'src/app/_models/productor/productor';

import { latLng, tileLayer, icon, marker } from 'leaflet';

@Component({
  selector: 'app-productor-details',
  templateUrl: './productor-details.component.html',
  styleUrls: ['./productor-details.component.sass']
})
export class ProductorDetailsComponent implements OnInit {
  id: number
  currentAccount: any
  productor: Productor
  setting: any

  submitted: boolean = false
  modalForm: NgbModalRef
  refusedForm: FormGroup
  acceptForm: FormGroup

  options = []

  constructor( 
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    public helpers: Helpers,
    private _productor: ProductorService,
    private _account: AccountService,
    private _authentication: AuthenticationService,
    private _pdf: PDFService,
    private _setting: SettingService
    ) { }

  ngOnInit() {
    this._authentication.currentAccount.subscribe(x => { 
      this.currentAccount = x

      if(this.currentAccount.accountRole.name != 'Restraint'){
        this.route.params.subscribe(params => {
          this.id = +params['id'];
          this._productor.getProductor(this.id).subscribe( data => {
            this.productor = data
            console.log(this.productor)
            this.createMapOption()
          })

          this.refusedForm = this.formBuilder.group({
            message: ['']
          })
        })
      }
      else{
        this._setting.getSetting(1).subscribe( data => {
          this.setting = data;
        })
        
        this._productor.getProductor(this.currentAccount.productorId).subscribe( data => {
          this.productor = data
          this.createMapOption()
        })
        this.acceptForm = this.formBuilder.group({
          id:[this.currentAccount.id, Validators.required],
          validationProductorData:["", Validators.requiredTrue]
        })
      } 
    })
  }

  createMapOption(){

    for( var i=0; i<this.productor.plots.length; i++){
      var opt = {
        layers: [
          tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: 'UGPBAN map'
          }),
          marker([ this.productor.plots[i].latitude, this.productor.plots[i].longitude ], {
            icon: icon({
              iconSize: [ 25, 41 ],
              iconAnchor: [ 13, 41 ],
              iconUrl: 'leaflet/marker-icon.png',
              shadowUrl: 'leaflet/marker-shadow.png'
            })
          })
        ],
        zoom: 14,
        center: latLng([ this.productor.plots[i].latitude, this.productor.plots[i].longitude ])
      }

      this.options.push(opt)
    }
    

  }

  get f() { return this.acceptForm.controls }


  goBack(){
    this.router.navigate(['productors']);
  }

  onSubmitAccepted(){
    this.productor.refused = false
    this._pdf.generatePDFCP(this.id).subscribe(data => {
    })
    this._pdf.generatePDFAttestation(this.id).subscribe(data => {
    })
    this._account.createAccount(this.productor).subscribe( data => {
      this.router.navigate(['/productors'])
    })
  }

  onSubmitRefused(content){
    this.modalForm = this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'})
  }

  onSubmit(){
    this.modalForm.dismiss()
    this.productor.refused = true
    this.productor.message = this.refusedForm.value.message
    this._account.createAccount(this.productor).subscribe( data => {
      this.router.navigate(['/productors'])
    })
  }

  onSubmitProductor(){
    this.submitted = true

    if(this.acceptForm.invalid){
      return
    }

    this.currentAccount.validationProductorData = true
    
    this._account.updateAccount(this.currentAccount).subscribe(data => {
      //sessionStorage.setItem('currentAccount', JSON.stringify(this.currentAccount));
      this._authentication.reloadSession(this.currentAccount)
      this.router.navigate(['/subscription/cgcp'])
    })
  }

}

