import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-legal-informations',
  templateUrl: './legal-informations.component.html',
  styleUrls: ['./legal-informations.component.sass']
})
export class LegalInformationsComponent implements OnInit {

  constructor( router: Router) { }

  ngOnInit() {
  }

}
