import { Component, OnInit} from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router'
import { CONFIG } from '../../app.constants';

// SERVICE //
import { AuthenticationService } from './../../_services/authentication.service'
import { AccountService } from './../../_services/account.service'
import { PDFService } from './../../_services/pdf.service'
import { SettingService } from './../../_services/setting.service'

@Component({
  selector: 'app-validation-cgcp',
  templateUrl: './validation-cgcp.component.html',
  styleUrls: ['./validation-cgcp.component.sass']
})
export class ValidationCgcpComponent implements OnInit {

  srcCG: any = "./../../assets/img/CG.pdf"
  srcCP: any = "./../../assets/img/CP.pdf"

  currentAccount: any
  acceptForm: FormGroup
  submitted = false
  PDFtoShow: number = 1
  setting: any

  constructor(
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private router: Router,
    private _authentication: AuthenticationService,
    private _account: AccountService,
    private _pdf: PDFService,
    private _setting: SettingService) {}

  ngOnInit() {
    this.srcCG =  this.sanitizer.bypassSecurityTrustResourceUrl(this.srcCG + "#toolbar=0")
    this.srcCP =  this.sanitizer.bypassSecurityTrustResourceUrl(this.srcCP + "#toolbar=0")

    this._setting.getSetting(1).subscribe( data => {
      this.setting = data;
    })

    this._authentication.currentAccount.subscribe(x => { 
      this.currentAccount = x

      this._pdf.getPDFCP(this.currentAccount.productorId)
        .then(
          data => {
            var fileURL = URL.createObjectURL(data);
            this.srcCP = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL + "#toolbar=0");
          }
        )

      // this.srcCP = this.sanitizer.bypassSecurityTrustResourceUrl(CONFIG.apiUrl[CONFIG.mode]+"pdf/44")
      this.acceptForm = this.formBuilder.group({
        id:[this.currentAccount.id, Validators.required],
        validationAcceptSpecialCondition:["", Validators.requiredTrue]
      })
    });
  }

  get f() { return this.acceptForm.controls }

  onSubmit(){
    this.submitted = true

    if(this.acceptForm.invalid){
      return
    }

    this.currentAccount.validationAcceptSpecialCondition = true
    this.currentAccount.roleId = 1
    this._account.updateAccount(this.currentAccount).subscribe(data => {
     
      //sessionStorage.setItem('currentAccount', JSON.stringify(this.currentAccount));
      //this.router.navigate(['/account'])
      this._authentication.login(this.currentAccount.login, this.currentAccount.accountPassword[0].password).subscribe( d => {
        this._authentication.reloadSession(d.account)
        this.router.navigate(['/account/1'])
      })
    })
  }

  ShowPDF(PDFNumber: number) {
    this.PDFtoShow = PDFNumber
  }

}
