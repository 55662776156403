import { Component, OnInit, Sanitizer } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router'
import { CONFIG } from '../app.constants';

// SERVICE //
import { AuthenticationService } from './../_services/authentication.service'
import { AccountService } from './../_services/account.service'
import { PDFService } from './../_services/pdf.service'

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.sass']
})
export class AccountComponent implements OnInit {

  srcCG: any = "./../../assets/img/CG.pdf"
  srcCP: any
  srcAttestation: any

  currentAccount: any
  acceptForm: FormGroup
  submitted = false
  PDFtoShow: number = 0

  constructor(
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private _authentication: AuthenticationService,
    private _account: AccountService,
    private _pdf: PDFService) {}

  ngOnInit() {
    this.srcCG =  this.sanitizer.bypassSecurityTrustResourceUrl(this.srcCG)

    this._authentication.currentAccount.subscribe(x => { 
      this.currentAccount = x

      this._pdf.getPDFCP(this.currentAccount.productorId)
        .then(
          data => {
            var fileURL = URL.createObjectURL(data);
            this.srcCP = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
          }
        )

        this._pdf.getPDFAttestation(this.currentAccount.productorId)
        .then(
          data => {
            var fileURL = URL.createObjectURL(data);
            this.srcAttestation = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
          }
        )
    });

    this.route.params.subscribe(params => {
      this.PDFtoShow = +params['pdf'];
    })
  }

  ShowPDF(PDFNumber: number) {
    this.PDFtoShow = PDFNumber
  }
}
