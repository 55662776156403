import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CONFIG } from '../app.constants';
import { Broker } from '../_models/broker/broker';

// MODEL //
//import { City } from './../_models/city/city';

@Injectable({
    providedIn: 'root'
  })
export class BrokerService {
    constructor(private http: HttpClient) { }

    private extractData(res: Response) {
        const body = res;
        return body || { };
    }

    getBrokers(): Observable<any> {
        return this.http.get(CONFIG.apiUrl[CONFIG.mode] + 'broker').pipe(
          map(this.extractData));
    }

    getBroker(id: number): Observable<any> {
        return this.http.get(CONFIG.apiUrl[CONFIG.mode] + `broker/${id}`).pipe(
          map(this.extractData));
    }

    updateBroker(id: number, broker: Broker): Observable<any>{
      return this.http.post(CONFIG.apiUrl[CONFIG.mode] + `broker/${id}`, JSON.stringify(broker))
      .pipe(
        map(this.extractData)
      )
  }
}