import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CONFIG } from '../app.constants';

import { AccountPassword } from './../_models/account/account'

@Injectable({
    providedIn: 'root'
  })
export class AccountPasswordService {
    constructor(private http: HttpClient) { }

    private extractData(res: Response) {
        const body = res;
        return body || { };
    }

    getAccountPasswordByAccountId(id: number): Observable<any> {
        return this.http.get(CONFIG.apiUrl[CONFIG.mode] + `account/password/${id}`).pipe(
          map(this.extractData));
    }

    updateAccountPassword(accountPassword: AccountPassword): Observable<any>{
        return this.http.post(CONFIG.apiUrl[CONFIG.mode] + `account/password/first/${accountPassword.id}`, JSON.stringify(accountPassword))
        .pipe(
          map(this.extractData)
        )
    }
}