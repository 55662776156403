import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CONFIG } from '../app.constants';
import { ProductorsOrganisation } from '../_models/productors-organisation/productors-organisation';

// MODEL //
//import { City } from './../_models/city/city';

@Injectable({
    providedIn: 'root'
  })
export class ProductorsOrganisationService {
    constructor(private http: HttpClient) { }

    private extractData(res: Response) {
        const body = res;
        return body || { };
    }

    getProductorsOrganisations(): Observable<any> {
        return this.http.get(CONFIG.apiUrl[CONFIG.mode] + 'productorsOrganisation').pipe(
          map(this.extractData));
    }

    getProductorsOrganisation(id: number): Observable<any> {
        return this.http.get(CONFIG.apiUrl[CONFIG.mode] + `productorsOrganisation/${id}`).pipe(
          map(this.extractData));
    }

    updateProductorsOrganisation(id: number, productorsOrg: ProductorsOrganisation): Observable<any>{
      return this.http.post(CONFIG.apiUrl[CONFIG.mode] + `productorsOrganisation/${id}`, JSON.stringify(productorsOrg))
      .pipe(
        map(this.extractData)
      )
  }
}