import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

// SERVICES //
import { AuthenticationService } from "../_services/authentication.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted: boolean = false;
  showPassword = false;
  loading = false;
  returnUrl: string;

  error: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _authentication: AuthenticationService
  ) {}

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      login: ["", Validators.required],
      password: ["", Validators.required],
    });

    this._authentication.logout();
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
  }

  get controls() {
    return this.loginForm.controls;
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }
  onSubmit() {
    this.submitted = true;

    this.loading = true;
    this._authentication
      .login(this.loginForm.value.login, this.loginForm.value.password)
      .subscribe((data) => {
        if (data.state && data.account.id !== 0) {
          if (data.account.accountRole.name != "Producteur")
            this.router.navigate(["/productors"]);

          if (data.account.accountRole.name == "Restraint") {
            if (data.account.validationAcceptFunctionment != true) {
              this.router.navigate(["/subscription/functionnement"]);
            } else if (
              data.account.validationPasswordChangeFirstLogin != true
            ) {
              this.router.navigate(["/subscription/password"]);
            } else if (data.account.validationProductorData != true) {
              this.router.navigate(["/subscription/productor"]);
            } else if (data.account.validationAcceptSpecialCondition != true) {
              this.router.navigate(["/subscription/cgcp"]);
            }
          }

          if (data.account.accountRole.name == "Producteur") {
            this.router.navigate(["/account/1"]);
          }
        } else {
          this.error = data.errors[0];
          this.loading = false;
        }
      });
  }
}
