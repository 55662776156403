import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";

import { DataTablesModule } from "angular-datatables";

import { JwtInterceptor } from "./_helpers/jwt.interceptor";
import { AuthGuard } from "./_guards/auth.guard";
import { Helpers } from "./_helpers/helpers";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { AuthenticationService } from "./_services/authentication.service";
import { ProductorService } from "./_services/productor.service";

import { LoginComponent } from "./login/login.component";
import { HomeComponent } from "./home/home.component";
import { ProductorsComponent } from "./productors/productors.component";
import { ProductorComponent } from "./productors/productor/productor.component";
import { ProductorDetailsComponent } from "./productors/productor-details/productor-details.component";
import { PasswordChangeComponent } from "./password-change/password-change.component";
import { ValidationFunctionmentComponent } from "./subscription/validation-functionment/validation-functionment.component";
import { AccountComponent } from "./account/account.component";
import { AccountDocumentComponent } from "./account/account-document/account-document.component";
import { ConfigurationComponent } from "./configuration/configuration.component";
import { ProductorsOrganisationComponent } from "./configuration/productors-organisation/productors-organisation.component";
import { BrokerComponent } from "./configuration/broker/broker.component";
import { InformationsComponent } from "./informations/informations.component";
import { AssistanceComponent } from "./informations/assistance/assistance.component";
import { GeneralConditionsComponent } from "./informations/general-conditions/general-conditions.component";
import { VideosComponent } from "./informations/videos/videos.component";
import { PartnersComponent } from "./informations/partners/partners.component";
import { LegalInformationsComponent } from "./informations/legal-informations/legal-informations.component";
import { LegalNoticeComponent } from "./informations/legal-informations/legal-notice/legal-notice.component";
import { LegalMentionComponent } from "./informations/legal-informations/legal-mention/legal-mention.component";
import { ValidationCgcpComponent } from "./subscription/validation-cgcp/validation-cgcp.component";
import { AccountCotisationComponent } from "./account/account-cotisation/account-cotisation.component";
import { InsurerComponent } from "./configuration/insurer/insurer.component";
import { ConfigurationOpComponent } from "./configuration-op/configuration-op.component";
import { CommonModule } from "@angular/common";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ProductorsComponent,
    ProductorComponent,
    HomeComponent,
    ProductorDetailsComponent,
    PasswordChangeComponent,
    ValidationFunctionmentComponent,
    AccountComponent,
    AccountDocumentComponent,
    ConfigurationComponent,
    ProductorsOrganisationComponent,
    BrokerComponent,
    InformationsComponent,
    AssistanceComponent,
    GeneralConditionsComponent,
    VideosComponent,
    PartnersComponent,
    LegalInformationsComponent,
    LegalNoticeComponent,
    LegalMentionComponent,
    ValidationCgcpComponent,
    AccountCotisationComponent,
    InsurerComponent,
    ConfigurationOpComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgbModule,
    HttpClientModule,
    NgSelectModule,
    DataTablesModule,
    PdfViewerModule,
    LeafletModule.forRoot(),
  ],
  providers: [
    AuthGuard,
    Helpers,
    AuthenticationService,
    ProductorService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
