import { FormGroup } from '@angular/forms';

// custom validator to check that two fields match
export function ConfirmGPSCoordinates(controlName: string, controlName2: string, island: number) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const control2 = formGroup.controls[controlName2];
       
        //lattitude
        if(control.value < 15.946506 && island == 2 || control.value > 16.514368 && island == 2 || 
           control.value < 14.394803 && island == 1 || control.value > 14.878724 && island == 1){
               
            control.setErrors({ confirmGPSCoordinates: true })
        }
        else{
            //control.setErrors({ confirmGPSCoordinates: false });
        }


        //longitude
        if(control2.value < -61.809853 && island == 2 || control2.value > -61.171617 && island == 2 ||
           control2.value < -61.229124 && island == 1 || control2.value > -60.810346 && island == 1){
            
            control2.setErrors({ confirmGPSCoordinates: true })
        }
        else{
            //control2.setErrors({ confirmGPSCoordinates: false });
        }
    }
}