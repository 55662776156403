import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray,
} from "@angular/forms";

import { AuthenticationService } from "./../../_services/authentication.service";
import { ProductorService } from "./../../_services/productor.service";
import { Productor } from "./../../_models/productor/productor";

@Component({
  selector: "app-account-cotisation",
  templateUrl: "./account-cotisation.component.html",
  styleUrls: ["./account-cotisation.component.sass"],
})
export class AccountCotisationComponent implements OnInit {
  currentAccount: any;

  productor: Productor;

  cotisation: string;
  cotisationExtra: string;
  term: string;
  firstTerm: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _productor: ProductorService,
    private _authentication: AuthenticationService
  ) {}

  ngOnInit() {
    this._authentication.currentAccount.subscribe((x) => {
      this.currentAccount = x;
      console.log(this.currentAccount);

      this._productor
        .getProductor(this.currentAccount.productorId)
        .subscribe((data) => {
          this.productor = data;

          this.cotisation = (
            this.productor.averageProduction * 235 * 0.14 +
            data.productorsOrganisation.applicationFee
          )
            .toFixed(2)
            .toLocaleString("fr");
          this.cotisationExtra = (
            this.productor.averageProduction * 235 * 0.14 * 0.57 +
            data.productorsOrganisation.applicationFee
          )
            .toFixed(2)
            .toLocaleString("fr");
          //this.term = (this.productor.averageProduction * 205 * 0.14 * (10/12) * 0.33).toFixed(2).toLocaleString()
          //this.firstTerm = (this.productor.averageProduction * 205 * 0.14 * (10/12) * 0.34 +  data.productorsOrganisation.applicationFee).toFixed(2).toLocaleString('fr')
          console.log(this.productor);
        });
    });
  }
}
