import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

// SERVICE //
import { ProductorsOrganisationService } from '../_services/productors-organisation.service';
import { BrokerService } from '../_services/broker.service';
import { InsurerService } from '../_services/insurer.service'

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.sass']
})
export class ConfigurationComponent implements OnInit {

  dtOptions: DataTables.Settings = {}
  dtTrigger: Subject<any> = new Subject()
  productorsOrganisations: any

  dtOptions2: DataTables.Settings = {}
  dtTrigger2: Subject<any> = new Subject()
  brokers: any

  dtOptions3: DataTables.Settings = {}
  dtTrigger3: Subject<any> = new Subject()
  insurers: any

  constructor(private router: Router,
    private route: ActivatedRoute, 
    private _productorsOrganisation: ProductorsOrganisationService,
    private _broker: BrokerService,
    private _insurer: InsurerService 
  ) { }

  ngOnInit() {
    this.getProductorsOrganisations();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        url: '/assets/js/datatables/languages/french.json'
      },
      responsive: true,
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        // Unbind first in order to avoid any duplicate handler
        // (see https://github.com/l-lin/angular-datatables/issues/87)
        $('td', row).unbind('click');
        $('td', row).bind('click', () => {
          this.editProductorOrganisation(data[0]);
        });
        return row;
      }
    };

    this.getBrokers();
    this.dtOptions2 = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        url: '/assets/js/datatables/languages/french.json'
      },
      responsive: true,
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        // Unbind first in order to avoid any duplicate handler
        // (see https://github.com/l-lin/angular-datatables/issues/87)
        $('td', row).unbind('click');
        $('td', row).bind('click', () => {
          this.editBroker(data[0]);
        });
        return row;
      }
    };

    this.getInsurers();
    this.dtOptions3 = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        url: '/assets/js/datatables/languages/french.json'
      },
      responsive: true,
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        // Unbind first in order to avoid any duplicate handler
        // (see https://github.com/l-lin/angular-datatables/issues/87)
        $('td', row).unbind('click');
        $('td', row).bind('click', () => {
          this.editInsurer(data[0]);
        });
        return row;
      }
    };
  }

  getProductorsOrganisations() {
    this._productorsOrganisation.getProductorsOrganisations().subscribe( data => {
      this.productorsOrganisations = data
      this.dtTrigger.next();
    })
  }

  editProductorOrganisation(id: number){
    this.router.navigate(['/configuration/productors-organisation', id])
  }

  getBrokers() {
    this._broker.getBrokers().subscribe( data => {
      this.brokers = data
      this.dtTrigger2.next();
    })
  }

  editBroker(id: number){
    this.router.navigate(['/configuration/broker', id])
  }

  getInsurers() {
    this._insurer.getInsurers().subscribe( data => {
      this.insurers = data
      this.dtTrigger3.next();
    })
  }

  editInsurer(id: number){
    this.router.navigate(['/configuration/insurer', id])
  }

}
