import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-informations',
  templateUrl: './informations.component.html',
  styleUrls: ['./informations.component.sass']
})
export class InformationsComponent implements OnInit {

  constructor(
    private router: Router,) {}

  ngOnInit() {
  }

}
