import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray,
} from "@angular/forms";

import { AccountPasswordService } from "./../_services/account-password";
import { AuthenticationService } from "./../_services/authentication.service";
import { SettingService } from "./../_services/setting.service";
import { AccountPassword } from "../_models/account/account";

import { ConfirmPassword } from "../_helpers/confirm-password.validator";

@Component({
  selector: "app-password-change",
  templateUrl: "./password-change.component.html",
  styleUrls: ["./password-change.component.sass"],
})
export class PasswordChangeComponent implements OnInit {
  submitted: boolean;
  showPassword: boolean = false;
  showPasswordCopy: boolean = false;
  passwordForm: FormGroup;
  id: number;
  accountPassword: AccountPassword;
  currentAccount: any;
  setting: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _accountPassword: AccountPasswordService,
    private _authentication: AuthenticationService,
    private _setting: SettingService
  ) {}

  ngOnInit() {
    this._setting.getSetting(1).subscribe((data) => {
      this.setting = data;
    });

    this._authentication.currentAccount.subscribe((x) => {
      this.currentAccount = x;

      this.passwordForm = this.formBuilder.group(
        {
          id: [this.currentAccount.id, Validators.required],
          password: ["", Validators.required],
          passwordCopy: ["", Validators.required],
        },
        {
          validator: ConfirmPassword("password", "passwordCopy"),
        }
      );
    });
  }

  get controls() {
    return this.passwordForm.controls;
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  togglePasswordCopyVisibility(): void {
    this.showPasswordCopy = !this.showPasswordCopy;
  }

  onSubmit() {
    this.submitted = true;

    if (this.passwordForm.invalid) {
      return;
    }

    //this.accountPassword = this.passwordForm.value
    this._accountPassword
      .updateAccountPassword(this.passwordForm.value)
      .subscribe((data) => {
        this.currentAccount.validationPasswordChangeFirstLogin = true;
        this.currentAccount.accountPassword[0].password =
          this.passwordForm.value.password;

        this._authentication.reloadSession(this.currentAccount);
        this.router.navigate(["/subscription/productor"]);
      });
  }
}
