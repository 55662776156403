import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CONFIG } from '../app.constants';
import { Insurer } from '../_models/insurer/insurer';

@Injectable({
    providedIn: 'root'
  })
export class InsurerService {
    constructor(private http: HttpClient) { }

    private extractData(res: Response) {
        const body = res;
        return body || { };
    }

    getInsurers(): Observable<any> {
        return this.http.get(CONFIG.apiUrl[CONFIG.mode] + 'insurer').pipe(
          map(this.extractData));
    }

    getInsurer(id: number): Observable<any> {
        return this.http.get(CONFIG.apiUrl[CONFIG.mode] + `insurer/${id}`).pipe(
          map(this.extractData));
    }

    updateInsurer(id: number, insurer: Insurer): Observable<any>{
      return this.http.post(CONFIG.apiUrl[CONFIG.mode] + `insurer/${id}`, JSON.stringify(insurer))
      .pipe(
        map(this.extractData)
      )
  }
}