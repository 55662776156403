import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CONFIG } from '../app.constants';
import { Setting } from '../_models/setting/setting';

// MODEL //
//import { City } from './../_models/city/city';

@Injectable({
    providedIn: 'root'
  })
export class SettingService {
    constructor(private http: HttpClient) { }

    private extractData(res: Response) {
        const body = res;
        return body || { };
    }

    getSetting(id: number): Observable<any> {
        return this.http.get(CONFIG.apiUrl[CONFIG.mode] + `setting/${id}`).pipe(
          map(this.extractData));
    }

    updateSetting(id: number, setting: Setting): Observable<any>{
      return this.http.post(CONFIG.apiUrl[CONFIG.mode] + `setting/${id}`, JSON.stringify(setting))
      .pipe(
        map(this.extractData)
      )
  }
}