import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Helpers } from '../_helpers/helpers'

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, public helpers: Helpers) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (sessionStorage.getItem('currentAccount')) {
            // logged in so return true
            if(route.data && route.data.right){
                return this.helpers.haveRightTo(route.data.right)
            }else{
                return true
            }
            
            //return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}