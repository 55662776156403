import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray,
} from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

import { ConfirmGPSCoordinates } from "../../_helpers/confirm-gps-coordinates.validators";

// SERVICE //
import { ProductorService } from "./../../_services/productor.service";
import { CityService } from "./../../_services/city.service";
import { CivilityService } from "./../../_services/civility.service";
import { AuthenticationService } from "./../../_services/authentication.service";

// MODELE //
import { Productor } from "./../../_models/productor/productor";
import { PDFService } from "./../../_services/pdf.service";
import { ProductorsOrganisation } from "./../../_models/productors-organisation/productors-organisation";

@Component({
  selector: "app-productor",
  templateUrl: "./productor.component.html",
  styleUrls: ["./productor.component.sass"],
})
export class ProductorComponent implements OnInit {
  id: number;

  productorForm: FormGroup;
  submitted: boolean;

  modalForm: NgbModalRef;

  productor: Productor;
  op: ProductorsOrganisation;
  opId: number;

  plotNumber: number = 0;
  plotLimit: number = 40;

  cities$0: any[] = [];
  civilities$0: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private _productor: ProductorService,
    private _city: CityService,
    private _civility: CivilityService,
    private _authentication: AuthenticationService,
    private _pdf: PDFService
  ) {}

  ngOnInit() {
    this._civility.getCivilitiesDropdown().subscribe((data) => {
      this.civilities$0 = data;
    });

    this.route.params.subscribe((params) => {
      this.id = +params["id"];

      // AJOUT //
      if (!this.id) {
        this._authentication.currentAccount.subscribe((dataCurrentOP) => {
          this.opId = dataCurrentOP.productorsOrganisationId;
          this._city
            .getCitiesDropdown(dataCurrentOP.productorsOrganisationId)
            .subscribe((dataCities) => {
              this.cities$0 = dataCities;

              this.productorForm = this.formBuilder.group({
                productorsOrganisationId: [
                  dataCurrentOP.productorsOrganisationId,
                  Validators.required,
                ],
                reference: [
                  this.createRefProductor(
                    dataCurrentOP.productorsOrganisationId
                  ),
                  Validators.required,
                ],
                companyName: ["", Validators.required],
                firstnameRes: ["", Validators.required],
                lastnameRes: ["", Validators.required],
                mailRes: [
                  "",
                  Validators.compose([Validators.required, Validators.email]),
                ],
                telRes: [
                  "",
                  Validators.compose([
                    Validators.required,
                    Validators.minLength(10),
                    Validators.maxLength(10),
                    Validators.pattern("[0-9]+"),
                  ]),
                ],
                address: ["", Validators.required],
                postalCode: [
                  "",
                  Validators.compose([
                    Validators.required,
                    Validators.minLength(5),
                    Validators.maxLength(5),
                    Validators.pattern("[0-9]{1}[A-Z0-9a-z]{1}[0-9]+"),
                  ]),
                ],
                cityId: [this.cities$0[0].id, Validators.required],
                civilityId: [1, Validators.required],
                productorHistories: this.formBuilder.array([
                  this.createProductorHistory(this.getYearMinus(1)),
                  this.createProductorHistory(this.getYearMinus(2)),
                  this.createProductorHistory(this.getYearMinus(3)),
                ]),
                plots: this.formBuilder.array(
                  [this.createPlot()],
                  Validators.required
                ),
              });
            });
        });
      }
      // MODIFICATION //
      else {
        this._productor.getProductor(this.id).subscribe((dataProductor) => {
          this.productor = dataProductor;
          this.opId = dataProductor.productorsOrganisationId;

          this._city
            .getCitiesDropdown(this.productor.productorsOrganisationId)
            .subscribe((dataCities) => {
              this.cities$0 = dataCities;

              this.productorForm = this.formBuilder.group({
                productorsOrganisationId: [
                  this.productor.productorsOrganisationId,
                  Validators.required,
                ],
                reference: [this.productor.reference, Validators.required],
                companyName: [this.productor.companyName, Validators.required],
                firstnameRes: [
                  this.productor.firstnameRes,
                  Validators.required,
                ],
                lastnameRes: [this.productor.lastnameRes, Validators.required],
                mailRes: [
                  this.productor.mailRes,
                  Validators.compose([Validators.required, Validators.email]),
                ],
                telRes: [
                  this.productor.telRes,
                  Validators.compose([
                    Validators.required,
                    Validators.minLength(10),
                    Validators.maxLength(10),
                    Validators.pattern("[0-9]+"),
                  ]),
                ],
                address: [this.productor.address, Validators.required],
                postalCode: [
                  this.productor.postalCode,
                  Validators.compose([
                    Validators.required,
                    Validators.minLength(5),
                    Validators.maxLength(5),
                    Validators.pattern("[0-9]{1}[A-Z0-9a-z]{1}[0-9]+"),
                  ]),
                ],
                cityId: [this.productor.cityId, Validators.required],
                civilityId: [this.productor.civilityId, Validators.required],
                plots: this.formBuilder.array([], Validators.required),
                productorHistories: this.formBuilder.array([]),
                statusId: [this.productor.statusId, Validators.required],
              });
              this.initPlot(this.productor.plots);
              this.initProductorHistory(this.productor.productorHistories);
            });
        });
      }
    });
  }

  // AJOUT //
  createPlot() {
    this.plotNumber++;

    return this.formBuilder.group(
      {
        reference: [1, Validators.required],
        cityId: [this.cities$0[0].id, Validators.required],
        latitude: ["", Validators.pattern("([-][0-9])?[0-9]*([.][0-9]+)?")],
        longitude: ["", Validators.pattern("([-][0-9])?[0-9]*([.][0-9]+)?")],
        surface: [
          "",
          Validators.compose([
            Validators.required,
            Validators.maxLength(4),
            Validators.pattern("[0-9]*([.][0-9]+)?"),
          ]),
        ],
      },
      {
        validator: ConfirmGPSCoordinates("latitude", "longitude", this.opId),
      }
    );
  }

  addPlot() {
    if (this.plotNumber >= this.plotLimit) return;

    const control = <FormArray>this.productorForm.controls["plots"];
    control.push(this.createPlot());
  }

  createProductorHistory(year: number) {
    return this.formBuilder.group({
      year: [year, Validators.required],
      totalProduction: [
        "",
        Validators.compose([
          Validators.required,
          Validators.maxLength(4),
          Validators.pattern("[0-9]+"),
        ]),
      ],
    });
  }

  // MODIFICATION //

  initPlot(plots: any) {
    this.plotNumber = plots.length;
    if (this.plotNumber == 0) this.createPlot();
    const control = <FormArray>this.productorForm.controls["plots"];
    let index = 0;
    for (let plot of plots) {
      let formTmp = this.formBuilder.group(
        {
          cityId: [plots[index].cityId, Validators.required],
          latitude: [
            plots[index].latitude,
            Validators.pattern("([-][0-9])?[0-9]*([.][0-9]+)?"),
          ],
          longitude: [
            plots[index].longitude,
            Validators.pattern("([-][0-9])?[0-9]*([.][0-9]+)?"),
          ],
          surface: [
            plots[index].surface,
            Validators.compose([
              Validators.required,
              Validators.maxLength(4),
              Validators.pattern("[0-9]*([.][0-9]+)?"),
            ]),
          ],
        },
        {
          validator: ConfirmGPSCoordinates("latitude", "longitude", this.opId),
        }
      );
      control.push(formTmp);
      index++;
    }
  }

  initProductorHistory(productorHistories: any) {
    const control = <FormArray>(
      this.productorForm.controls["productorHistories"]
    );
    let index = 0;

    for (let productorHistory of productorHistories) {
      let formTmp = this.formBuilder.group({
        year: [productorHistory.year, Validators.required],
        totalProduction: [
          productorHistory.totalProduction,
          Validators.compose([
            Validators.required,
            Validators.maxLength(4),
            Validators.pattern("[0-9]+"),
          ]),
        ],
      });
      control.push(formTmp);
      index++;
    }
  }

  // COMMUN //
  deletePlot(index) {
    const control = <FormArray>this.productorForm.controls["plots"];
    control.removeAt(index);
    this.plotNumber--;
  }

  /*createMap(){
    const myfrugalmap = L.map('frugalmap').setView([50.6311634, 3.0599573], 12);
 
    L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
      attribution: 'UGPBAN map'
    }).addTo(myfrugalmap);
  }*/

  get f() {
    return this.productorForm.controls;
  }

  get plots() {
    return this.productorForm.get("plots") as FormArray;
  }

  goBack() {
    this.router.navigate(["productors"]);
  }

  getYearMinus(n: number) {
    var date = new Date(2023, 1, 1);
    date.setFullYear(date.getFullYear() - n);
    return date.getFullYear();
  }

  createRefProductor(idOp: number): string {
    let result = this.getYearMinus(0).toString().substring(2);
    result += Math.floor(Math.random() * 10);
    result += Math.floor(Math.random() * 10);
    result += Math.floor(Math.random() * 10);
    result += idOp;

    return result;
  }

  createRefPlot(num: number): string {
    let result =
      this.productorForm.value.reference +
      "/" +
      num; /*this.productor.reference + "/" +*/
    return result;
  }

  addReftoAllPlot() {
    for (
      let i = 0;
      i < this.productorForm.controls["plots"].value.length;
      i++
    ) {
      let ref = this.createRefPlot(i + 1);
      this.productorForm.controls["plots"].value[i].reference = ref;
    }
  }

  onModalSubmit(content) {
    this.submitted = true;

    if (this.productorForm.invalid) {
      return;
    }

    this.modalForm = this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
    });
  }

  onSubmit() {
    this.modalForm.dismiss();
    //this.submitted = true;
    if (this.productorForm.invalid) {
      return;
    }

    this.addReftoAllPlot();
    var idProductor = 0;
    if (this.productor) {
      idProductor = this.productor.id;
    }
    this.productor = this.productorForm.value;
    if (this.productor.statusId != 4 && this.productor.statusId != 2) {
      this.productor.statusId = 1;
    }

    if (this.id) {
      this._productor
        .updateProductor(this.id, this.productor)
        .subscribe((data) => {
          this.productor = data;
          console.log(data);
          if (this.productor.statusId == 2 || this.productor.statusId == 4) {
            this._pdf.generatePDFCP(idProductor).subscribe((data) => {});
            this._pdf
              .generatePDFAttestation(idProductor)
              .subscribe((data) => {});
          }

          this.router.navigate(["productors"]);
        });
    } else {
      this._productor
        .insertProductor(this.productorForm.value)
        .subscribe((data) => {
          this.productor = data;
          this.router.navigate(["productors"]);
        });
    }
  }
}
