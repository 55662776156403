import { Component, OnInit, Sanitizer } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router'

// SERVICE //
import { AuthenticationService } from './../../_services/authentication.service'
import { PDFService } from './../../_services/pdf.service'

@Component({
  selector: 'app-account-document',
  templateUrl: './account-document.component.html',
  styleUrls: ['./account-document.component.sass']
})
export class AccountDocumentComponent implements OnInit {

  srcCG: any = "./../../assets/img/CG.pdf"
  srcCP: any
  srcAttestation: any

  currentAccount: any
  acceptForm: FormGroup
  submitted = false
  PDFtoShow: number = 0

  constructor(
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private router: Router,
    private _authentication: AuthenticationService,
    private _pdf: PDFService) {}

  ngOnInit() {
    this.srcCG =  this.sanitizer.bypassSecurityTrustResourceUrl(this.srcCG)
    this.srcCP =  this.sanitizer.bypassSecurityTrustResourceUrl(this.srcCP)

    this._authentication.currentAccount.subscribe(x => { 
      this.currentAccount = x

      this._pdf.getPDFCP(this.currentAccount.productorId)
        .then(
          data => {
            var fileURL = URL.createObjectURL(data);
            this.srcCP = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
          }
        )

        this._pdf.getPDFAttestation(this.currentAccount.productorId)
        .then(
          data => {
            var fileURL = URL.createObjectURL(data);
            this.srcAttestation = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
          }
        )
    });
  }

  ShowPDF(PDFNumber: number) {
    this.PDFtoShow = PDFNumber
  }

}
