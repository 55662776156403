import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CONFIG } from '../app.constants';

// MODEL //
//import { Civility } from './../_models/civility/civility';

@Injectable({
    providedIn: 'root'
  })
export class CivilityService {
    constructor(private http: HttpClient) { }

    private extractData(res: Response) {
        const body = res;
        return body || { };
    }

    getCivilitiesDropdown(): Observable<any> {
        return this.http.get(CONFIG.apiUrl[CONFIG.mode] + 'civility/GetDropdown').pipe(
          map(this.extractData));
    }
}