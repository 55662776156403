import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { InsurerService } from 'src/app/_services/insurer.service';
import { Insurer } from 'src/app/_models/insurer/insurer';

@Component({
  selector: 'app-insurer',
  templateUrl: './insurer.component.html',
  styleUrls: ['./insurer.component.sass']
})
export class InsurerComponent implements OnInit {
  id: number

  insurerForm: FormGroup
  submitted: boolean

  insurer: Insurer

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _insurer: InsurerService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = +params['id'];
      
      this._insurer.getInsurer(this.id).subscribe( data => {
        this.insurer = data

        this.insurerForm = this.formBuilder.group({
          name: [this.insurer.name, Validators.required],
          mail: [this.insurer.mail, Validators.required]
        })
      })
    });
  }

  get f() { return this.insurerForm.controls }

  goBack(){
    this.router.navigate(['configuration']);
  }

  onSubmit(){
    this.submitted = true;

    if(this.insurerForm.invalid){
      return
    }

    this.insurer = this.insurerForm.value

    this._insurer.updateInsurer(this.id, this.insurerForm.value).subscribe(data => {
        this.insurer = data
        this.router.navigate(['configuration'])
    }) 
  }

}
