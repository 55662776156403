import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Helpers } from '../_helpers/helpers';

// SERVICE //
import { ProductorService } from './../_services/productor.service'
import { AuthenticationService } from './../_services/authentication.service'

@Component({
  selector: 'app-productors',
  templateUrl: './productors.component.html',
  styleUrls: ['./productors.component.sass']
})
export class ProductorsComponent implements OnInit {

  dtOptions: DataTables.Settings = {}
  dtTrigger: Subject<any> = new Subject()

  currentAccount: any

  productors: any

  constructor(
    private router: Router,
    public helpers: Helpers,
    private _productors: ProductorService,
    private _authentication: AuthenticationService
  ) { }

  ngOnInit() {
    this.getProductors()

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      order: [0, 'desc'],
      language: {
        url: '/assets/js/datatables/languages/french.json'
      },
      responsive: true,
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        // Unbind first in order to avoid any duplicate handler
        // (see https://github.com/l-lin/angular-datatables/issues/87)
        $('td', row).unbind('click');
        $('td', row).bind('click', () => {
          if(this.helpers.haveRightTo('UpdateProductor')){
            this.editProductor(data[0]);
          }
          if(this.helpers.haveRightTo('ReadCardProductor')){
            this.detailsProductor(data[0]);
          }
        });
        return row;
      }
    };
  }

  getProductors() {
    this._authentication.currentAccount.subscribe(x => { 
      this.currentAccount = x
      this._productors.getProductors(this.currentAccount.productorsOrganisationId == null ? 0 : this.currentAccount.productorsOrganisationId).subscribe( data => {
        this.productors = data
        this.dtTrigger.next();
      })
    })
    
  }

  editProductor(id: number){
    this.router.navigate(['/productors/productor', id])
  }

  detailsProductor(id: number){
    this.router.navigate(['/productors/productor/details', id])
  }

}
