import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CONFIG } from '../app.constants';

import {Productor} from './../_models/productor/productor'

@Injectable({
    providedIn: 'root'
  })
export class AccountService {
    constructor(private http: HttpClient) { }

    private extractData(res: Response) {
        const body = res;
        return body || { };
    }

    getAccount(id: number): Observable<any> {
      return this.http.get(CONFIG.apiUrl[CONFIG.mode] + `account/${id}`).pipe(
        map(this.extractData));
    }

    createAccount(productor: Productor): Observable<any> {
        return this.http.post(CONFIG.apiUrl[CONFIG.mode] + 'account', productor).pipe(
          map(this.extractData));
    }

    updateAccount(account: Account): Observable<any> {
      return this.http.post(CONFIG.apiUrl[CONFIG.mode] + `account/${account.id}`, JSON.stringify(account)).pipe(
        map(this.extractData));
  }
}